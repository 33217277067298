class CategoryAdmin {

    constructor(container = null) {
        this.container = container;
        if (this.container !== null) {
            this.container = $(this.container);
            if (this.container.length > 0) {
                this.init();
            }
        }
    }

    async getCategories() {
        try {
            let result = await $.ajax({
                url: "api/categories/list"
            });
            return result.categories;
        } catch (e) {
            new Notification().error("Kategorien konnten nicht geladen werden");
            return [];
        }
    }

    async init() {
        this.initEvents();
        let categories = await this.getCategories();        
        this.container.html("");
        this.createRecursiveList(this.container, categories);

        let sortableOptions = {
            forcePlaceholderSize: true,
            handle: 'div',
            items: 'li',
            maxLevels: 7,
            toleranceElement: '> div',
            placeholder: "placeholder",
            listType: 'ul'
        };
        this.container.nestedSortable(sortableOptions);
    }

    createCategoryEntry(category) {
        return $(`
            <li class="category_list_entry" id="category_${category.id}" data-id="${category.id}">
                <div></div>
            </li>
        `);
    }

    createRecursiveList(ul, categories) {                
        for (let category of categories) {
            let li = this.createCategoryEntry(category);
            ul.append(li);            
            if (category.children.length > 0) {
                let childList = $("<ul></ul>");
                li.append(childList);
                this.createRecursiveList(childList, category.children);
            }
            this.updateEntryLabel(category);
        }
    }

    addCategoryEntry(category) {
        if (category.parent != "-1") {
            let parent = this.container.find("#category_" + category.parent);
            if (parent.find("> ul").length === 0) {
                parent.append("<ul></ul>");
            }
            parent.find("> ul").prepend(this.createCategoryEntry(category));
        } else {
            this.container.prepend(this.createCategoryEntry(category));
        }
        this.updateEntryLabel(category);
    }

    updateEntryLabel(category) {
        let div = $("#category_" + category.id);
        let name = category.name;
        let color = category.color;
        let icon = category.icon;
        let faicon = category.faicon;
        let iconPath = category.iconPath;

        div.data("name", name);
        div.data("faicon", faicon);
        div.data("icon", icon);
        div.data("color", color);

        let iconImage = "";
        if (iconPath != null) {
            iconImage = `<img src="${iconPath}" class="category_icon">`;
        }
        let iconFaIcon = "";
        if (faicon !== "") {
            iconFaIcon = `<i class="${faicon}"></i>`
        }

        this.container.find("#category_" + category.id +" > div:first").html(`
            <div class="category_color_preview" style="background-color: ${color}"></div>
            <div class="category_icon_preview">${iconImage}</div>        
            <div class="category_faicon_preview">${iconFaIcon}</div>        
            <span class="category_name">${name}</span>
            <i class="material-icons clickable minimize">unfold_less</i>
            <i class="material-icons clickable maximize">unfold_more</i>
            <i class="material-icons clickable minimize_all">unfold_less_double</i>
            <i class="material-icons clickable maximize_all">unfold_more_double</i>
            <span style="width: 1em;"></span>
            <i class="material-icons category-delete clickable">delete</i>
            <i class="material-icons category-add clickable">add</i>
            <i class="material-icons category-edit clickable">edit</i>
        `);
        this.updateExpansionButtons(category.id);
    }
    async create(category) {
        try {
            let result = await $.ajax({
                url: "api/categories/create",
                method: "POST",
                data: {
                    "name": category.name,
                    "position": -1,
                    "parent": category.parent,
                    "color": category.color,
                    "icon": category.icon,
                    "faicon": category.faicon
                }
            });
            return result.category;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    async update(category) {
        try {
            let result = await $.ajax({
                url: "api/categories/update",
                method: "POST",
                data: {
                    "name": category.name,
                    "id": category.id,
                    "color": category.color,
                    "icon": category.icon,
                    "faicon": category.faicon
                }
            });
            return result.category;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    async delete(id) {
        try {
            await $.ajax({
                url: "api/categories/delete",
                method: "POST",
                data: {
                    "id": id
                }
            });
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }
    
    async save(entries = null) {
        if (entries === null) {
            entries = this.container.nestedSortable("toArray", {startDepthCount: 0});
        }
        let data = new Array();

        for (let i = 0; i < entries.length; ++i) {
            if (typeof entries[i].id === "undefined") {
                continue;
            }
            let parent = -1;
            if (entries[i].parent_id != null) {
                parent = entries[i].parent_id;
            }

            let id = entries[i].id.split("_").pop();
            let ob = {
                id: id,
                parent: parent,
                position: i
            }
            data.push(ob);
        }
        try {
            await $.ajax({
                method: "POST",
                data: {
                    data: data
                },
                url: "api/categories/order"
            });
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    evaluateDialog(title, action, buttonAction, name, icon, faicon, color, callback) {
        let dlg = new Dialog();
        let confirm = function() {
            let node = dlg.getNode();
            let newName = node.find(".category_name_input").val();
            let newColor = node.find(".category_color_input").val();
            let newIcon = node.find("#dlg_category_icon").val();
            let newFaIcon = node.find("#dlg_category_faicon").val();
            callback(dlg, newName, newIcon, newFaIcon, newColor);
        };

        dlg.build()
            .addTitle(title)
            .addText(action)
            .addHtml(`
                <div class="input-field">
                    <label for="dlg_category_name">Kategoriename</label>
                    <input type="text" id="dlg_category_name" class="category_name_input" value="${name}"/>
                </div>
                <div>
                    <label>Farbe</label><br />
                    <input type="text" id="dlg_category_color" class="pageit_color_input category_color_input" value="${color}" />
                </div>
                <div>
                    <label>Font Awesome Icon</label><br />
                    <input type="text" id="dlg_category_faicon" class="category_faicon_input" value="${faicon}" />
                </div>
                <div class="input-field">
                    <div class="adminpictureselect"
                        data-input="dlg_category_icon"
                        data-value="${icon}"
                        data-title="Icon auswählen"
                        data-name="Icon"></div>
                </div>
            `)
            .addButton("Abbrechen", "cancel", dlg.close.bind(dlg))
            .addButton(buttonAction, "save", confirm.bind(dlg))
            .onEnter(confirm.bind(dlg))
            .show();
        initAdminPictureSelect(dlg.getNode().find(".adminpictureselect"));
        initAdminColorInput(dlg.getNode().find(".pageit_color_input"));
    }

    updateExpansionButtons(id) {
        let li = this.container.find("#category_" + id);
        let ul = li.find("> ul");
        if (ul.length) {
            if (ul.is(":visible")) {                
                li.find("> div > .minimize").show();
                li.find("> div > .maximize").hide();                
            } else {
                li.find("> div > .minimize").hide();
                li.find("> div > .maximize").show();                
            }
        } else {
            li.find("> div > .minimize").hide();
            li.find("> div > .maximize").hide();
        }
    }

    minimize(id) {
        this.container.find("#category_" + id).find("> ul").slideUp("fast", function() {;
            this.updateExpansionButtons(id);
        }.bind(this));
    }

    maximize(id) {
        this.container.find("#category_" + id).find("> ul").slideDown("fast", function() {;
            this.updateExpansionButtons(id);
        }.bind(this));
    }

    minimizeChildren(id) {
        let that = this;
        this.container.find("#category_" + id).find("> ul > li").each(function() {
            that.minimize($(this).data("id"));
        });
    }

    maximizeChildren(id) {
        let that = this;
        this.maximize(id);
        this.container.find("#category_" + id).find("> ul > li").each(function() {
            that.maximize($(this).data("id"));
        });
    }

    initEvents() {
        let that = this;
        // Minimize & Maximize
        this.container.on("click", ".minimize", function() {
            that.minimize($(this).parent().parent().data("id"));
        });
        this.container.on("click", ".maximize", function() {
            that.maximize($(this).parent().parent().data("id"));
        });
        this.container.on("click", ".minimize_all", function() {
            that.minimizeChildren($(this).parent().parent().data("id"));
        });
        this.container.on("click", ".maximize_all", function() {
            that.maximizeChildren($(this).parent().parent().data("id"));
        });

        let createCategoryDialog = function(parent = -1) {
            that.evaluateDialog("Kategorie erstellen", "Erstellen Sie eine neue Kategorie", "Erstellen", "", -1, "", "", async function(dlg, name, icon, faIcon, color) {
                if (name === "") {
                    new Notification().info("Der Name darf nicht leer sein");
                    return;
                }
                let createdCategory = await that.create({
                    name: name,
                    icon: icon,
                    faicon: faIcon,
                    color: color,
                    parent: parent,
                    position: -1
                });
                if (!createdCategory) {
                    new Notification().error("Kategorie konnte nicht erstellt werden");
                    return;
                }
                
                dlg.close();
                new Notification().info("Kategorie erstellt");
                that.addCategoryEntry(createdCategory);
            });  
        };

        // Entry Creation
        $("body").on("click", "#admin_categories_action_add", function() {
              createCategoryDialog(-1);
        });
        $("body").on("click", ".category-add", function() {
            let id = $(this).parent().parent().data("id");
            createCategoryDialog(id);
        });
        // Entry Deletion
        $("body").on("click", ".category-delete", function() {
            let li = $(this).parent().parent();
            let id = li.attr("id").split("_").pop();
            let name = li.find(".category_name").text();
            var dlg = new Dialog();
            dlg.confirm(
                "Bestätigen",
                'Wollen Sie die Kategorie "' + name + '" und alle Unterkategorien wirklich unwiderruflich löschen?',
                "Ja",
                "Nein",
                async function(confirm) {
                    if (confirm) {
                        if (await that.delete(id)) {
                            new Notification().success(`Die Kategorie "${name}" wurde gelöscht`);
                            li.slideUp("fast", function() {
                                let ul = li.parent();
                                li.remove();
                                if (ul.children().length === 0) {
                                    ul.remove();
                                }

                            });
                        } else {
                            new Notification().error(`Die Kategorie "${name}" konnte nicht gelöscht werden`);
                        }                        
                    }
                }
            );
        });
        // Entry Edit
        let editCategoryDialog = function(li) {
            let id = li.attr("id").split("_").pop();
            let name = li.data("name");
            let icon = li.data("icon");
            let faicon = li.data("faicon");
            let color = li.data("color");

            that.evaluateDialog("Kategorie bearbeiten", "Bearbeiten Sie die Kategorie", "Aktualisieren", name, icon, faicon, color, async function(dlg, name, icon, faIcon, color) {
                if (name === "") {
                    new Notification().info("Der Name darf nicht leer sein");
                    return;
                }
                let updatedCategory = await that.update({
                    id: id,
                    name: name,
                    icon: icon,
                    faicon: faIcon,
                    color: color
                });
                if (!updatedCategory) {
                    new Notification().error("Kategorie konnte nicht aktualisiert werden");
                    return;
                }
                dlg.close();
                new Notification().info("Kategorie aktualisiert");
                that.updateEntryLabel(updatedCategory);
                
            });
        }
        this.container.on("click", ".category-edit", function() {
            let li = $(this).parent().parent();
            editCategoryDialog(li);
        });
        this.container.on("dblclick", ".category_list_entry > div", function() {
            let li = $(this).parent();
            editCategoryDialog(li);
        });


        $("#admin_categories_action_save").on("click", async function() {
            if (await that.save()) {
                new Notification().success("Änderungen wurden übernommen");
            } else {
                new Notification().error("Änderungen konnten nicht gespeichert werden");
            }
        });

        $("#admin_categories_action_minimize_all").on("click", function() {
            that.container.find("> li").each(function() {
                that.minimize($(this).data("id"));
                that.minimizeChildren($(this).data("id"));
            })
        });

        $("#admin_categories_action_maximize_all").on("click", function() {
            that.container.find("> li").each(function() {
                that.maximizeChildren($(this).data("id"));
            })
        });
    }
}

$(function() {
    new CategoryAdmin("#categories");
});
