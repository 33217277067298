class AddressbookAdmin {
    constructor() {
        if ($("#addressbook-admin").length === 0) {
            return;
        }
        this.container = $("#addressbook-admin");
        this.container.html("");
        this.init();
    }

    async init() {
        let buildings = await CategorySemantics.getBuildings();
        let privateBuildings = await CategorySemantics.getPrivateBuildings();

        this.container.append("<h3>Öffentliche Gebäude</h3>");
        if (buildings.length === 0) {
            this.container.append("<i>Keine öffentlichen Gebäude vorhanden</i>");
        } else {
            let publicGrid = $(`<div class="row automasonry" colwidth="m6"></div>`);
            this.container.append(publicGrid);
            for (let buildingId in buildings) {            
                publicGrid.append(await this.makeBuildingCard(buildings[buildingId]));
            }
        }

        this.container.append("<h3>Private Gebäude</h3>");
        if (privateBuildings.length === 0) {
            this.container.append("<i>Keine privaten Gebäude vorhanden</i>");
        } else {        
            let privateGrid = $(`<div class="row automasonry" colwidth="m6"></div>`);
            this.container.append(privateGrid);
            for (let buildingId in privateBuildings) {
                privateGrid.append(await this.makeBuildingCard(privateBuildings[buildingId]));
            }
        }

        $("body").off("click", ".building-addressbook-osm");
        $("body").on("click", ".building-addressbook-osm", async function() {
            console.log("OSM");
            let buildingId = $(this).data("building");
            let street = $(`#building-${buildingId}-street`).val().trim();
            let streetNumber = $(`#building-${buildingId}-street_number`).val().trim();
            let zip = $(`#building-${buildingId}-zip`).val().trim();
            let city = $(`#building-${buildingId}-city`).val().trim();
            if (street === "" || streetNumber === "" || zip === "" || city === "") {
                new Notification().info("Bitte erst die Anschrift vollständig ausfüllen");
                return;
            }
            let data = await OpenStreetMap.structuredSearch(street, streetNumber, zip, city);
            if (data !== null) {
                $(`#building-${buildingId}-osm`).val(JSON.stringify(data));
            } else {
                new Notification().warning("Die Daten konnten nicht abgerufen werden");
            }
        });

        $("body").on("click", "#save-addressbook", this.save.bind(this));
        this.container.find(".addressbook-page").each(function() {
            initAdminPObjectSelect($(this));
        })
        M.updateTextFields();
    }

    async save() {
        let data = {};
        let that = this;
        this.container.find(".building-addressbook").each(function() {
            let buildingId = $(this).data("building");
            data[buildingId] = that.getAddressbookAttributes($(this));
        });
        try {
            await $.ajax({
                "url": "api/categories/addressbook_set",
                "type": "POST",
                "data": {
                    "addressbook": data
                }
            });
            new Notification().info("Gespeichert");
        } catch (e) {
            console.error(e);
            new Notification().error("Speichern fehlgeschlagen");
        }
    }

    getAddressbookAttributes(card) {
        // let buildingId = card.data("building");
        let data = {};
        card.find("input, select").each(function() {
            data[$(this).attr("name")] = $(this).val();
        });
        return data;
    }

    makeInputField(building, key, name, defaultValue = "") {
        let value = defaultValue;
        try {
            if (key in building.attributes.attribute) {
                value = building.attributes.attribute[key].value;
            }
        } catch (e) {
            console.error(e);
        }

        let field =$(`
            <div class="input-field">
                <label for="building-${building.id}-${key}">${name}</label>
                <input type="text" id="building-${building.id}-${key}" name="${key}" />
            </div>
        `);
        field.find("input").val(value);
        return field;
    }

    async makeBuildingCard(building) {
        let district = await CategorySemantics.getAssociatedDistrict(building);
        let districtInfo = "";
        if (district !== false) {
            districtInfo = `(${district.name})`;
        }
        let page = -1;
        try {
            page = building.attributes.attribute.page.value ?? "-1";
        } catch (e) {
            
        }

        let col = $(`
        <div class="col s12 m6">
            <div class="card">
                <div class="card-content building-addressbook" data-building="${building.id}">
                    <span class="card-title">#${building.id} - ${building.name} ${districtInfo}</span>   
                </div>
            </div>
        </div>`);
        let card = col.find(".card-content");
        card.append(this.makeInputField(building, "display_name", "Anzeigename", building.name));
        card.append(this.makeInputField(building, "street", "Straße"));
        card.append(this.makeInputField(building, "street_number", "Hausnummer"));
        card.append(this.makeInputField(building, "zip", "PLZ"));
        card.append(this.makeInputField(building, "city", "Ort", "Duisburg"));
        card.append(preparePObjectSelect({
            objectclass: "\\P\\Posts\\Page",
            divClass: "addressbook-page",
            objectname: "Seite",
            input: `addressbook-page-${building.id}`,
            name: "page",
            value: page,
            title: "Zugehörige Seite auswählen",
            objectlabler: "default:page-hierarchy",
            objectsorter: "default:page-hierarchy"
        }));
        card.append(this.makeInputField(building, "osm", "OSM-Daten", ""));
        card.append(`                 
            <div class="right-align">
                <button type="button" class="btn waves-effect amber darken-4 white-text building-addressbook-osm" data-building="${building.id}">
                    <i class="material-icons left">map</i>
                    OSM-Daten laden
                </button>
            </div>
        `);
        return col;
    }
}

$(function() {
    let addressbookAdmin = new AddressbookAdmin();
});