class Categories {
    static async getBadge(category, opts = {}) {
        let options = Object.assign({
            "type": "category",
            "locationToDistrict": true,
            "data": {}
        }, opts);
        let type = options.type;
        let icon = "";
        let data = [];
        for (let key in options.data) {
            data.push(`data-${key}="${options.data[key]}"`);
        }
        switch (type) {
            case "location":
                icon = `<i class="fa-solid fa-location-dot"></i>`;
                if (options.locationToDistrict) {
                    category = await CategorySemantics.getAssociatedDistrict(category);
                }
                break;
            case "targetgroup":
                icon = `<i class="fa-solid fa-user-group"></i>`;
                break;
            case "activity":
                icon = `<i class="fa-solid fa-tag"></i>`;
                break;
        }
        if (category.faicon !== "") {
            icon = `<i class="${category.faicon}"></i>`;
        }
        if (category.icon !== "-1" && category.icon !== "" && category.iconPath !== null) {
            icon = `<img src="${category.iconPath}" class="badge-image">`
        }
        return `<span data-category="${category.id}" class="group-category category-badge" ${data.join(" ")}>${icon}${category.name}</span>`;
    }

    static verifyFilter(filterCategories, targetCategories, any, filterCategoryPath, targetCategoryPath) {      
        let matched = true;
        for (let filterCategory of filterCategories) {
            matched = false;
            for (let groupCategory of targetCategories) {
                if (filterCategory.id === groupCategory.id) {                    
                    matched = true;
                } else if (filterCategoryPath && filterCategory.path.includes(groupCategory.id)) {                    
                    matched = true;
                } else if (targetCategoryPath && groupCategory.path.includes(filterCategory.id)) {                    
                    matched = true;
                }
            }
            if (any && matched) {
                return true;
            } else if (!any && !matched) {
                return false;
            }
        }
        return matched;
    }
}
