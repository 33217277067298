class SemanticsAdmin {
    constructor() {
        if ($("#semantics-admin").length === 0) {
            return;
        }
        this.init();
    }

    init() {
        this.initBindings();
    }

    updateSelectors(selectors, data = {}) {
        for (let selector of selectors) {
            let selectorData = {};
            if (selector in data) {
                selectorData = data[selector];
            }
            initAdminPObjectSelect($(`#categories-locations-${selector}-selector`), true, selectorData);
        }
    }

    async autodetect(parents) {
        if (!Array.isArray(parents)) {
            parents = [];
        }
        if (parents.length === 0) {
            new Notification().error(`Erkennung fehlgeschlagen: Keine ${parentNames} verfügbar.`);
            return false;
        }
        try {
            let result = await $.ajax({
                url: "api/categories/info",
                data: {
                    "categories": parents
                }
            });
            let autodetectedCategories = [];
            for (let category of result.categories) {
                autodetectedCategories = autodetectedCategories.concat(category.children);
            }
            return autodetectedCategories;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    applyAutodetect(name, children) {
        if (!children) {
            return false;
        }
        let childrenIds = [];
        for (let child of children) {
            childrenIds.push(child["id"]);
        }

        let allSelectors = ["districts", "buildings", "floors", "rooms"];
        let data = {};
        let selectors = [];
        let found = false;
        for (let selector of allSelectors) {
            if (selector === name) {
                found = true;
                data[selector] = {"value": childrenIds};
            }
            if (found) {
                selectors.push(selector);
            }
        }

        this.updateSelectors(selectors, data);
        new Notification().success("Erkennung abgeschlossen");
    }

    async autodetectDistricts() {
        let parents = $("#categories-locations-parents").val();
        let children = await this.autodetect(parents, "Orte");
        this.applyAutodetect("districts", children);        
    }

    async autodetectBuildings() {
        let parents = $("#categories-locations-districts").val();
        let children = await this.autodetect(parents, "Regionen / Stadtteile");
        this.applyAutodetect("buildings", children);        
    }

    async autodetectFloors() {
        let parents = $("#categories-locations-buildings").val();
        let children = await this.autodetect(parents, "Gebäude");
        this.applyAutodetect("floors", children);        

    }

    async autodetectRooms() {
        let parents = $("#categories-locations-floors").val();
        let children = await this.autodetect(parents, "Etagen");
        this.applyAutodetect("rooms", children);        
    }

    initBindings() {
        $("#semantics-admin").on("click", "#semantics-autodetect-districts", this.autodetectDistricts.bind(this));
        $("#semantics-admin").on("click", "#semantics-autodetect-buildings", this.autodetectBuildings.bind(this));
        $("#semantics-admin").on("click", "#semantics-autodetect-floors", this.autodetectFloors.bind(this));
        $("#semantics-admin").on("click", "#semantics-autodetect-rooms", this.autodetectRooms.bind(this));
    }
}

let semanticsAdmin;
$(function() {
    semanticsAdmin = new SemanticsAdmin();
})