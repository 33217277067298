class OpenStreetMap {
    lastRequest = 0
    requestLimitSeconds = 1;

    static async structuredSearch(street, streetNumber, zip, city) {
        let data = {
            "street": `${street} ${streetNumber}`,
            "postalcode": zip,
            "city": city
        };
        let results = await OpenStreetMap.query(data);
        if (results !== null && results.length > 0) {
            return OpenStreetMap.parsePlace(results[0]);
        }
        return null;
    }

    static async freeSearch(query, boundingBox = null) {
        let data = {
            "q": query
        };
        if (boundingBox !== null) {
            data["viewbox"] = boundingBox.join(",");
            data["bounded"] = 1;
        }
        let results = await OpenStreetMap.query(data);
        let parsedResults = [];
        if (results !== null && results.length > 0) {
            for (let result of results) {
                parsedResults.push(OpenStreetMap.parsePlace(result));
            }
        }
        return parsedResults;
    }

    static parsePlace(placeData) {
        let place = {};
        place.position = {
            "lat": placeData.lat,
            "lon": placeData.lon
        };
        if (Array.isArray(placeData.boundingbox) && placeData.boundingbox.length === 4) {
            place.boundingBox = [
                {"lat": placeData.boundingbox[0], "lon": placeData.boundingbox[2]},
                {"lat": placeData.boundingbox[1], "lon": placeData.boundingbox[3]}
            ];
        } else {
            place.boundingBox = null;
        }
        place.polygonString = placeData.geotext;
        place.name = placeData.name;
        place.license = placeData.license;
        place.osmId = placeData.osm_id;
        place.fullName = placeData.display_name;
        return place;
    }

    static async query(data) {
        data["format"] = "jsonv2";
        data["polygon_text"] = 1;
        try {
            return await $.ajax({
                "url": "https://nominatim.openstreetmap.org/search",
                "data": data,
                "type": "GET"
            });
        } catch (e) {
            console.error(e);
        }
        return null;
    }
}
