class CategorySelector {
    static selectId = 0;

    constructor(container, options = {}) {
        this.container = $(container);
        if (this.container.length === 0) {
            return;
        }
        this.container.addClass("category-selector-container");

        this.id = CategorySelector.selectId++;

        this.opts = Object.assign({
            "title": "Kategorien wählen",
            "icon": false,
            "selected": [],
            "multiple": true,
            "showIcon": true,
            "showColor": true,
            "colorSelection": "individual",
            "showFaIcon": true,
            "semantic": false,
            "categories": false,
            
            "onSelectionChange": false,
            "onSelect": false,
            "onDeselect": false,

            "syncWith": false,
            "syncAllowDelete": true,
            "syncDedicatedDeleteButton": true,
            "syncEnsureOwnElements": true,

            "toggle": true,
            "swap": true,
        }, options);

        this.selected = [];
        this.categories = [];
        this.categoriesById = {};
        this._initializePromise = this.init();
    }

    async initialized() {
        await this._initializePromise;
    }

    isSelected(category) {
        return this.selected.indexOf(category.id) >= 0;
    }

    onSelect(category, triggerCallbacks) {
        this.container.find(`.category-badge[data-category="${category.id}"]`).addClass("selected");
        
        if (this.opts.syncWith) {
            let sync = $(this.opts.syncWith);
            let clone = this.container.find(`.category-badge[data-category="${category.id}"]`).clone();
            sync.append(clone);

            if (this.opts.syncAllowDelete) {
                if (this.opts.syncDedicatedDeleteButton) {
                    clone.append(`<i class="fa-solid fa-xmark deletable" data-category="${category.id}"></i>`)
                } else {                    
                    clone.addClass("deletable");
                }
                let that = this;
                clone.on("click", ".deletable", function() {
                    that.deselect($(this).data("category"));
                });
            }

            let badges = sync.children(".category-badge").sort(function(a, b) {                
                if ($(a).data("selector-id") == $(b).data("selector-id")) {
                    return parseInt($(a).data("position")) - parseInt($(b).data("position"));
                }
                return parseInt($(a).data("selector-id")) - parseInt($(b).data("selector-id"));
            });
            sync.append(badges);
        }
        if (triggerCallbacks) {
            if (typeof this.opts.onSelect === "function") {
                this.opts.onSelect(category);
            }
            this.onSelectionChange();
        }
    }

    onDeselect(category, triggerCallbacks = true) {        
        if (this.opts.syncWith) {
            let sync = $(this.opts.syncWith);
            if (this.opts.syncEnsureOwnElements) {
                sync.find(`.category-badge[data-category="${category.id}"][data-selector-id="${this.id}"]`).remove();
            } else {
                sync.find(`.category-badge[data-category="${category.id}"]`).remove();
            }
        }

        this.container.find(`.category-badge[data-category="${category.id}"]`).removeClass("selected");     
        if (triggerCallbacks) {
            if (typeof this.opts.onDeselect === "function") {
                this.opts.onDeselect(category);
            }
            this.onSelectionChange();
        }
    }

    onSelectionChange() {
        if (typeof this.opts.onSelectionChange === "function") {
            this.opts.onSelectionChange(this.getSelection());
        }
    }

    async select(category, triggerCallbacks = true) {
        await this.initialized();
        if (typeof category !== "object") {
            category = this.categoriesById[category];
        }

        if (!this.isSelected(category)) {
            this.selected.push(category.id);
            this.onSelect(category, triggerCallbacks);
        }
    }

    async deselect(category, triggerCallbacks = true) {
        await this.initialized();
        if (typeof category !== "object") {
            category = this.categoriesById[category];
        }

        let index = this.selected.indexOf(category.id);
        if (index >= 0) {
            this.selected.splice(index, 1);
            this.onDeselect(category, triggerCallbacks);
        }
    }

    async clearSelection(triggerCallbacks = true) {
        for (let category of this.getSelection()) {
            this.deselect(category, triggerCallbacks);
        }
    }

    getSelection() {
        let selection = [];
        for (let categoryId of this.selected) {
            selection.push(this.categoriesById[categoryId]);
        }
        return selection;
    }

    async init() {
        if (this.opts.categories === false) {
            if (this.opts.semantic === false) {
                this.categories = [];
            } else {
                this.categories = await CategorySemantics.getSemantic(this.opts.semantic);
            }
        } else {
            this.categories = this.opts.categories;
        }

        this.categories = this.categories.sort(function(a, b) {
            return parseInt(a.position) - parseInt(b.position);
        });

        this.categoriesById = {};
        for (let category of this.categories) {
            this.categoriesById[category.id] = category;
        }
        
        let name = `category-select-${this.id}`;
        let multiple = "";
        if (this.opts.multiple) {
            multiple = `multiple="multiple"`;
            name = `${name}[]`;
        }

        let icon = "";
        if (this.opts.icon !== "" && this.opts.icon !== false) {
            icon = `<i class="${this.opts.icon}"></i>`;
        }

        let dropdown = $(`
            <div class="dropdown">
                <button class="btn dropdown-toggle category-selector-toggle-button" type="button" data-bs-auto-close="outside" data-bs-toggle="dropdown">
                    ${icon}${this.opts.title}
                </button>
            </div>`);        
        this.container.append(dropdown);

        let dropdownContents = $(`<div class="dropdown-menu category-select-menu"></div>`)        
        dropdown.append(dropdownContents);

        for (let category of this.categories) {
            dropdownContents.append(await Categories.getBadge(category, {data: {"selector-id": this.id, "position": category.position}}));
        }

        let that = this;


        this.container.on("click", ".category-badge", function() {
            let categoryId = $(this).data("category");
            let category = that.categoriesById[categoryId];
            if (!category) {
                console.error("Invalid category: " + categoryId);
                return;
            }

            if (that.opts.multiple) {
                if (that.isSelected(category)) {                    
                    // Only deselect when toggle is active
                    if (that.opts.toggle) {
                        that.deselect(category);
                    }
                } else {
                    // Select if not already selected
                    that.select(category);
                }
            } else {
                if (that.isSelected(category)) {
                    // Only deselect when toggle is active
                    if (that.opts.toggle) {
                        that.deselect(category);
                    }
                } else {
                    // If another category is selected, check if it can be swapped
                    let selection = this.getSelection();
                    if (selection.length > 0) {
                        if (that.opts.swap) {
                            // Deselect currently selected one
                            that.deselect(selection[0]);
                            // Select new one
                            that.select(category);
                        }
                    } else {
                        // Nothing selected, just select the clicked category
                        that.select(category);
                    }
                }
            }
        });

        for (let category of this.opts.selected) {
            if (typeof category === "object") {
                this.select(category, false);    
            } else {
                this.select(this.categoriesById[category], false);
            }
        }
    }
}