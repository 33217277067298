class CategorySemantics {
    static semanticCache = new RequestCache();

    static async getSemantics(name, forceReload = false) {
        let unlock = await CategorySemantics.semanticCache.lock(name);
        let semantics = {};
        
        if (!forceReload && CategorySemantics.semanticCache.hasCache(name)) {
            unlock();
            return CategorySemantics.semanticCache.getCache(name, {});
        } else {
            try {
                let result = await $.ajax({
                    url: "api/categories/semantics",
                    data: {
                        names: [name]
                    }
                });
                return CategorySemantics.semanticCache.cacheAndUnlock(name, result.semantics, unlock);
            } catch (e) {
                console.log(e);
                new Notification().error("Semantiken konnten nicht geladen werden");
                unlock();
                semantics[name] = [];
            }
        }
        return semantics;
    }

    static async getSemantic(name, forceReload = false) {
        let semantics = await CategorySemantics.getSemantics(name, forceReload);
        return semantics[name];
    }

    static async getSemanticObject(name, forceReload = false) {
        let semantic = await CategorySemantics.getSemantic(name, forceReload);
        let semanticObjects = {};
        for (let category of semantic) {
            semanticObjects[category.id] = category;
        }
        return semanticObjects;
    }

    static async checkForParents(category, semanticName, forceReload = false) {
        let candidates = await CategorySemantics.getSemanticObject(semanticName, forceReload);
        let results = [];
        if (category.id in candidates) {
            results.push(category);
        }
        for (let parent of category.path) {
            if (parent in candidates) {
                results.push(candidates[parent]);
            }
        }
        return results;
    }

    static tryGetFirst(array, defaultValue = false) {
        if (array.length === 0) {
            return defaultValue;
        }
        return array[0];
    }

    static async getAssociatedDistricts(category, forceReload = false) {
        return await CategorySemantics.checkForParents(category, "locations/districts", forceReload);
    }

    static async getAssociatedDistrict(category, forceReload = false) {
        return CategorySemantics.tryGetFirst(await CategorySemantics.getAssociatedDistricts(category, forceReload));        
    }

    static async getAssociatedBuildings(category, forceReload) {
        return await CategorySemantics.checkForParents(category, "locations/buildings", forceReload);
    }

    static async getAssociatedBuilding(category, forceReload = false) {
        return CategorySemantics.tryGetFirst(await CategorySemantics.getAssociatedBuildings(category, forceReload));        
    }

    static async getAssociatedFloors(category, forceReload) {
        return await CategorySemantics.checkForParents(category, "locations/floors", forceReload);
    }

    static async getAssociatedFloor(category, forceReload = false) {
        return CategorySemantics.tryGetFirst(await CategorySemantics.getAssociatedFloors(category, forceReload));        
    }

    static async getRooms(forceReload = false) {
        return await CategorySemantics.getSemanticObject("locations/rooms", forceReload);
    }

    static async getPrivateBuildings(forceReload = false) {
        let privateBuildings = await CategorySemantics.getSemanticObject("privatebuildings", forceReload);
        for (let buildingId in privateBuildings) {
            privateBuildings[buildingId].isPrivate = true;
        }
        return privateBuildings;
    }

    static async getBuildings(includePrivateBuildings = false, forceReload = false) {
        let buildings = await CategorySemantics.getSemanticObject("locations/buildings", forceReload);
        let allBuildings = {};
        for (let buildingId in buildings) {
            buildings[buildingId].isPrivate = false;
            allBuildings[buildingId] = buildings[buildingId];
        }
        let privateBuildings = {};
        if (includePrivateBuildings) {
            privateBuildings = await CategorySemantics.getPrivateBuildings(forceReload);
            allBuildings = Object.assign(allBuildings, privateBuildings);
        }        
        return allBuildings;
    }

    static async getObjectById(objectOrId, semantic, defaultValue = null, forceReload = false) {
        if (typeof objectOrId === "object") {
            return objectOrId;
        }
        let objects = await CategorySemantics.getSemanticObject(semantic, forceReload);
        if (objectOrId in objects) {
            return objects[objectOrId];
        }
        return defaultValue;
    }

    static async getDistrict(objectOrId, forceReload = false) {
        return CategorySemantics.getObjectById(objectOrId, "locations/districts", null, forceReload);
    }

    static async getBuilding(objectOrId, forceReload = false) {
        return CategorySemantics.getObjectById(objectOrId, "locations/buildings", null, forceReload);
    }
    
    static async getFloor(objectOrId, forceReload = false) {
        return CategorySemantics.getObjectById(objectOrId, "locations/floors", null, forceReload);
    }
    
    static async getRoom(objectOrId, forceReload = false) {
        return CategorySemantics.getObjectById(objectOrId, "locations/rooms", null, forceReload);
    }
    
    static async getTargetGroup(objectOrId, forceReload = false) {
        return CategorySemantics.getObjectById(objectOrId, "targetgroups", null, forceReload);
    }
    
    static async getEventType(objectOrId, forceReload = false) {
        return CategorySemantics.getObjectById(objectOrId, "events", null, forceReload);
    }
    
    static async getActivity(objectOrId, forceReload = false) {
        return CategorySemantics.getObjectById(objectOrId, "activities", null, forceReload);
    }
}
