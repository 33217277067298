
class PageItMap {
    constructor(container, options = {}) {
        this.container = $(container);        
        this.options = {
            mapType: "osm",
            
            buildings: [],
            addresses: [],
            /*
            Format:
            {
                "street": "",
                "streetNumber": "",
                "zipCode": "",
                "city": ""
            }
            */
            
            enableAddressSearch: true,
            addressSearchPlaceholder: "Suchen Sie nach einer Adresse",

            height: "auto",
            
            showBuildingMarkers: true,
            showBuildingPolygons: false,
            buildingBorderColor: "#000000",
            buildingMarkerColor: "auto",

            showGoogleMapsLink: false,
            
            autoOpenPopups: true,
            allowFullscreen: false,
            showControls: false
        };
        this.options = Object.assign(this.options, options);
        if (!(this.options.mapType in PageItMap.mapConfigurations)) {
            console.error("Invalid map Type");
            return;
        }
        let buildingIds = [];
        for (let id of this.options.buildings) {
            buildingIds.push(parseInt(id));
        }
        this.options.buildings = buildingIds;

        this.isFullScreen = false;

        this.lastSearchQuery = "";
        this.lastSearchResults = null;
        this.searchBounds = null;
        this.searchResultsContainer = null;
        this.searchResultMarkers = [];
        this.searchResultPopovers = [];
        
        this.mapDiv = null;
        this.controlDiv = null;
        this.loadingDiv = null;

        // Leaflet layers and maps
        this.map = null;
        this.tileLayer = null;
        this.elements = {};
        this.defaultPopup = null;

        this.tileConfig = PageItMap.mapConfigurations[this.options.mapType];
        this.init();
        $(window).on("resize", this.onResize.bind(this));
    }

    async init() {
        this.container.addClass("pageit-map");
        this.container.html(`
            <div class="map-controls"></div>
            <div class="map"></div>
            <div class="loading-overlay">                
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Wird geladen...</span>
                </div>
                <span>Karte wird geladen</span>
            </div>
        `);
        this.mapDiv = this.container.find(".map");
        this.loadingDiv = this.container.find(".loading-overlay");
        this.controlDiv = this.container.find(".map-controls");

        this.mapDiv.hide();
        this.controlDiv.hide();
        
        await this.getBuildings();
        this.resizeMap();   
        this.loadingDiv.hide();
        this.mapDiv.show();
        await this.initMap();
        await this.draw();     

        if (this.options.showControls) {
            this.setupControls();
            this.controlDiv.show();
        }
    }

    setupControls() {
        if (this.options.enableAddressSearch) {
            this.controlDiv.append(`
                <div class="address-search">
                    <div class="input-group">
                        <input type="text" class="form-control address-search-input" placeholder="${this.options.addressSearchPlaceholder}" aria-label="Suche" aria-describedby="basic-addon2">
                        <button class="address-search-button btn btn-outline-secondary" type="button">
                            Suchen
                        </button>
                        <button class="address-search-clear-button btn btn-outline-secondary" type="button">
                            <i class="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                    <div class="search-results">
                        <div class="results"></div>
                    </div>
                </div>
            `);
            this.searchResultsContainer = this.controlDiv.find(".search-results");
            this.searchResultsContainer.hide();

            let input = this.controlDiv.find(".address-search-input");
            let button = this.controlDiv.find(".address-search-button");
            let clearButton = this.controlDiv.find(".address-search-clear-button");

            let queryErrorPopover = new bootstrap.Popover(input[0], {
                content: "Bitte geben Sie eine längere Adresse ein",
                trigger: "manual",
                placement: "bottom"
            });

            input.on("keydown", function(e) {
                if (e.key === "Enter") {
                    this.triggerSearch(input, button, queryErrorPopover);
                } else {
                    queryErrorPopover.hide();
                }
            }.bind(this));

            button.on("click", function() {
                this.triggerSearch(input, button, queryErrorPopover);
            }.bind(this));

            clearButton.on("click", function() {
                this.clearSearch(input);
            }.bind(this));
        }
    }

    clearSearch(input) {
        input.val("");
        for (let marker of this.searchResultMarkers) {
            marker.remove();
        }
        this.searchResultMarkers = [];
        this.searchResultPopovers = [];
        this.fitBounds();
        if (this.searchResultsContainer.is(":visible")) {
            this.searchResultsContainer.slideUp();
        }
    }

    async triggerSearch(input, button, popover) {
        console.error("Search NIY");
        return;
        let address = input.val();
        if (address.length < 8) {                    
            popover.show();
            return;
        }
        if (address === this.lastSearchQuery) {
            this.showSearchResults(this.lastSearchResults);
            return;
        }
        popover.hide();
        input.prop("disabled", true);
        button.prop("disabled", true);

        this.lastSearchQuery = address;
        this.lastSearchResults = await this.searchForParish(address);
        this.showSearchResults(this.lastSearchResults);
        
        input.prop("disabled", false);
        button.prop("disabled", false);
    }

    async showSearchResults(results) {
        let resultDiv = this.searchResultsContainer.find(".results");
        
        for (let marker of this.searchResultMarkers) {
            marker.remove();
        }
        this.searchResultMarkers = [];   
        
        resultDiv.html("");

        let showResultDiv = false;

        if (results.length === 0) {
            // No results            
            resultDiv.append(`
                <div class="search-result no-result">
                    <b>Ihre Suche ergab keine Ergebnisse</b>
                </div>
            `);
            showResultDiv = true;
            this.fitBounds();
        } else if (results.length > 0) {
            // One or multiple results
            // Create markers on the map        
            
            let bounds = null;
            let sortedResults = {};

            for (let result of results) {
                let markerPoint = this.toLatLng(result.position);
                if (bounds === null) {
                    bounds = markerPoint.toBounds(2);
                } else {
                    bounds.extend(markerPoint);
                }

                let marker = this.getColoredMarker(markerPoint, "#FF8800");
                this.searchResultMarkers.push(marker);
                marker.addTo(this.map);
                // TODO: Popups?
            }


            if (results.length === 1) {
                // Single results
                showResultDiv = false;
                this.searchResultMarkers[0].openPopup();
            }
            this.map.fitBounds(bounds.pad(0.1), {maxZoom: 14});
        }
        if (!this.searchResultsContainer.is(":visible") && showResultDiv) {
            this.searchResultsContainer.slideDown();
        } else if (this.searchResultsContainer.is(":visible") && !showResultDiv) {
            this.searchResultsContainer.slideUp();
        }
    }

    formatContacts(contacts, prefixWithContacts = null, emptyPrefix = null) {
        let lines = [];        
        if (contacts.length > 0) {
            if (prefixWithContacts !== null) {
                lines.push(prefixWithContacts);
            }

            let contactsHtml = "";
            for (let contact of contacts) {
                contactsHtml += `
                    <div class="contact">
                        ${this.formatContact(contact)}
                    </div>
                `;
            }
            contactsHtml = `<div class="contacts">${contactsHtml}</div>`;
            lines.push(contactsHtml);
        } else {
            if (emptyPrefix !== null) {
                lines.push(emptyPrefix);
            }
        }
        return lines.join("");
    }

    isPositionInsidePolygon(position, polygonPoints) {            
        let x = position.lat;
        let y = position.lng;
    
        let inside = false;
        for (let i = 0, j = polygonPoints.length - 1; i < polygonPoints.length; j = i++) {
            let xi = polygonPoints[i].lat, yi = polygonPoints[i].lng;
            let xj = polygonPoints[j].lat, yj = polygonPoints[j].lng;
    
            let intersect = ((yi > y) != (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) {
                inside = !inside;
            }
        }    
        return inside;
    };

    getColoredMarker(position, color) {
        const markerHtmlStyles = `
            background-color: ${color};
            width: 3rem;
            height: 3rem;
            display: block;
            left: -1.5rem;
            top: -1.5rem;
            position: relative;
            border-radius: 3rem 3rem 0;
            transform: rotate(45deg);
            border: 1px solid #FFFFFF;
            scale: 0.6;
        `;

        let icon = L.divIcon({
            className: "my-custom-pin",
            iconAnchor: [0, 24],
            labelAnchor: [-6, 0],
            popupAnchor: [0, -36],
            html: `<span style="${markerHtmlStyles}" />`
        });

        return L.marker(position, {
            icon: icon
        });
    }

    formatContact(contact) {
        let attributes = contact.attributes.attribute;
        let contactParts = [`
            <b>${attributes.name.value}</b>
        `];
        if ("description" in attributes && attributes.description.value !== "") {
            contactParts.push(`<i>${attributes.description.value}</i>`);
        }
        if ("mail" in attributes && attributes.mail.value !== "") {
            contactParts.push(`<a href="mailto:${attributes.mail.value}">${attributes.mail.value}</a>`);
        }
        if ("phone" in attributes && attributes.phone.value !== "") {
            let phone = attributes.phone.value;
            let callablePhone = phone.replace(" ", "").replace("/", "").replace("-", "");
            contactParts.push(`<a href="tel:${callablePhone}">${phone}</a>`);
        }
        return contactParts.join("<br />");
    }

    async getContacts(parishes = null) {
        return [];        
    }

    onResize() {
        this.resizeMap();
        this.map.invalidateSize();
    }

    resizeMap() {
        // let height = $(window).height() - (this.container.offset().top - $(window).scrollTop());
        // height *= 0.9;
        let width = this.container.width();
        let height = 0;

        if (this.isFullScreen) {

        } else {
            switch (this.options.height) {
                case "auto":
                    if (width < 500) {
                        // Mobile
                        height = 500;
                    } else {
                        height = $(window).height() * 0.6;
                    }
                    break;
                case "aspect-4-3":
                    height = width * (3 / 4);
                    break;
                case "aspect-1-1":
                    height = width;
                    break;
                default:
                    height = 200;
                    if (this.options.height.includes("%")) {
                        let percentage = parseFloat(this.options.height.replace("%", ""));
                        height = $(window).height() * (percentage / 100);
                    } else if (this.options.height.includes("px")) {
                        height = Math.round(parseFloat(this.options.height.replace("px", "")));
                    }
            }
        }
        this.mapDiv.css("height", `${height}px`);
        this.mapDiv.css("width", `${width}px`);
    }

    async initMap() {
        let leafletOptions = PageItMap.leafletOptions;
        this.map = L.map(this.mapDiv[0], leafletOptions);
        this.map.attributionControl.setPrefix(`<a href="https://leaflet.com" target="_blank">Leaflet</a>`);
        await this.fitBounds();
        L.tileLayer(this.tileConfig["url"], this.tileConfig["opts"]).addTo(this.map);
    }

    async fitBounds() {
        let bounds = await this.getBounds();
        if (bounds !== null) {
            this.map.fitBounds(bounds.pad(0.1));
        }
    }

    async getBounds() {
        let buildings = await this.getFilteredBuildings();
        let bounds = null;
        for (let buildingId in buildings) {
            let building = buildings[buildingId];
            let osmData = building.osmData;
            if (typeof osmData !== "object" || Array.isArray(osmData) || osmData === null) {
                console.error(`No OSM Data for ${building.id} // ${building.name}`);
                continue;
            }
            let markerPoint = this.toLatLng(osmData.position);
            // let polygonPoints = this.toLatLngArray(osmData.polygonPoints);
            if (bounds === null) {
                bounds = markerPoint.toBounds(2);
            } else {
                bounds.extend(markerPoint);
            }
        }
        return bounds;
    }

    toLatLngArray(points) {
        let convertedPoints = [];
        for (let point of points) {
            convertedPoints.push(this.toLatLng(point));
        }
        return convertedPoints;
    }

    toLatLng(point) {
        if (Array.isArray(point)) {
            return L.latLng(parseFloat(point[1]), parseFloat(point[0]));
        }
        if ("lng" in point) {
            return L.latLng(parseFloat(point.lat), parseFloat(point.lng));
        } else if ("lon" in point) {
            return L.latLng(parseFloat(point.lat), parseFloat(point.lon));
        }
        return null;
    }

    async draw() {
        // Clear existing elements
        for (let elementId in this.elements) {
            this.elements[elementId].remove();
        }
        this.elements = {};

        
        // Buildings
        if (this.options.showBuildingMarkers || this.options.showBuildingPolygons) {
            let buildings = await this.getFilteredBuildings();
            for (let buildingId in buildings) {
                let building = buildings[buildingId];
                let osmData = building.osmData;
                if (typeof osmData !== "object" || Array.isArray(osmData) || osmData === null) {
                    console.error(`No OSM Data for ${building.id} // ${building.name}`);
                    continue;
                }

                // Draw Polygon
                if (this.options.showBuildingPolygons) {
                    let polygonPoints = this.toLatLngArray(osmData.polygonPoints);
                    let polyOptions = {
                        "color": this.options.buildingBorderColor,
                        "fillColor": "#ff0000",
                        "stroke": true,
                        "fill": false
                    }; 
                    let polygon = L.polygon(polygonPoints, polyOptions);
                    polygon.addTo(this.map);
                    this.elements[`building-polygon-${building.id}`] = polygon;
                    if (!this.options.showBuildingMarkers) {
                        polygon.bindPopup(this.getBuildingPopupHtml(building));
                        polygon.openPopup();
                    }
                }

                // Put marker on map
                if (this.options.showBuildingMarkers) {
                    let markerPoint = this.toLatLng(osmData.position);
                    let color = "#152C55";
                    if (this.options.buildingMarkerColor === "auto" || this.options.buildingMarkerColor === "building") {
                        if (building.color !== "") {
                            color = building.color;
                        }
                    }

                    let marker = this.getColoredMarker(markerPoint, color);
                    marker.addTo(this.map);
                    this.elements[`building-marker-${building.id}`] = marker;
                    marker.bindPopup(this.getBuildingPopupHtml(building));
                    if (this.options.autoOpenPopups) {
                        marker.openPopup();
                    }
                }                               
            }
        }
    }

    getBuildingPopupHtml(building) {
        let parts = [
            `<b>${building.name}</b>`
        ].concat(building.addressRows);
        if (this.options.showGoogleMapsLink) {
            let mapsLink = "https://www.google.com/maps/search/?api=1";
            let query = encodeURIComponent([building.street, building.streetNumber, , building.zip, building.city].join(" "));
            parts.push(`<a href="${mapsLink}&query=${query}" target="_blank">In Google Maps anzeigen</a>`);
        }
        return parts.join("<br />");
    }

    async getBuildings() {
        return await CategorySemantics.getBuildings(true);
    }

    async getFilteredBuildings() {
        let buildings = await this.getBuildings();
        let filteredBuildings = {};

        for (let buildingId in buildings) {
            let building = buildings[buildingId];
            if (this.options.buildings.includes(parseInt(building.id))) {
                filteredBuildings[buildingId] = buildings[buildingId];
            }
        }
        return filteredBuildings
    }

    static mapConfigurations = {
        "osm": {
            url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
            opts: {
                maxZoom: 19,
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
            }
        },
        "osm-wiki": {
            url: "https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png",
            opts: {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
            }
        },
        "osm-humanitarian": {
            url: "http://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
            opts: {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
            }    
        },
        "osm-toner": {
            url: "http://tile.stamen.com/toner/{z}/{x}/{y}.png",
            opts: {
                attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://www.openstreetmap.org/copyright">ODbL</a>.'
            }
        }
    }

    static leafletOptions = {
        zoomSnap: 0,
        doubleClickZoom: false
    }
}

$(function() {
    $(".pageit-map").each(function() {
        let options = $(this).data();
        let map = new PageItMap($(this), options);
    });
});